* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Nunito", sans-serif;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: url("./assets/bg.jpg") no-repeat;
  background-size: cover;
  min-height: 100dvh;
}

.flex {
  display: flex;
  align-items: center;
  gap: 5px;
}
